import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import ScrollAnimation from 'react-animate-on-scroll';

import Layout from "../components/Layout";
import Logo from "../components/Logo";
import Artwork from "../components/Artwork";



// eslint-disable-next-line
export const PlaylistsPageTemplate = ({
  title,
  heading,
  subheading,
  playlistTitle,
  playlistimage1,
  playlisturl1,
  playlistimage2,
  playlisturl2,
  playlistimage3,
  playlisturl3
}) => {


  return (
    <>
      <div className="innerhero">
        <Logo />
        <h1>{title}</h1>
      </div>
        <ScrollAnimation animateIn="fadeIn" animateOnce={true} delay="1500">
          <div className="inner">
            <Artwork
              title={heading}
              subtitle={subheading}
              description={playlistTitle}
              playlistImg1={playlistimage1}
              playlistURL1={playlisturl1}
              playlistImg2={playlistimage2}
              playlistURL2={playlisturl2}
              playlistImg3={playlistimage3}
              playlistURL3={playlisturl3}
            />
          </div>
        </ScrollAnimation>
    </>
  );
};

PlaylistsPageTemplate.propTypes = {
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
};

const PlaylistsPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <PlaylistsPageTemplate
        title={frontmatter.title}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        playlistTitle={frontmatter.playlistTitle}
        playlistSubTitle={frontmatter.playlistSubTitle}
        playlistDescription={frontmatter.playlistDescription}
        playlistimage1={frontmatter.playlistimage1}
        playlisturl1={frontmatter.playlisturl1}
        playlistimage2={frontmatter.playlistimage2}
        playlisturl2={frontmatter.playlisturl2}
        playlistimage3={frontmatter.playlistimage3}
        playlisturl3={frontmatter.playlisturl3}
      />
    </Layout>
  );
};

PlaylistsPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default PlaylistsPage;

export const PlaylistQuery = graphql`
  query PlaylistsPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "playlists-page" } }) {
      frontmatter {
        title
        heading
        subheading
        playlistTitle
        playlistSubTitle
        playlistDescription
        playlistimage1 {
          childImageSharp {
            fluid {
              src
            }
          }
        }
        playlisturl1
        playlistimage2 {
          childImageSharp {
            fluid {
              src
            }
          }
        }
        playlisturl2
        playlistimage3 {
          childImageSharp {
            fluid {
              src
            }
          }
        }
        playlisturl3
      }
    }
  }
`;
